import axios from "axios"

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("user")
    ? JSON.parse(window.localStorage.getItem("user"))
    : {}

export const setUser = user =>
  window.localStorage.setItem("user", JSON.stringify(user))

export const isLoggedIn = () => {
  const user = getUser()
  return user ? authenticateUser(user.login) : false
}

export const handleLogin = props => {
  return setUser(props)
}

export const mutationLogin = ({ login, password }) => {
  return {
    query: `
    mutation LoginUser {
      login( input: {
        username: "${login}",
        password: "${password}"
      } ) {
        authToken
        refreshToken
        user {
          email
          firstName
          lastName
          username
        }
      }
    }
  `,
  }
}

export const mutationRefresh = token => {
  return {
    query: `
    mutation {
      refreshJwtAuthToken(input: {jwtRefreshToken: "${token}"}) {
        authToken
      }
    }
    `,
  }
}

export const mutationResetPassword = ({ email }) => {
  return {
    query: `
    mutation ResetPassword {
      sendPasswordResetEmail(input: {username: "${email}"}) {
        user {
          name
        }
      }
    }
  `,
  }
}

export const authenticateUser = login => {
  return axios.post(
    `${process.env.GATSBY_ADMIN_URL}graphql`,
    {
      query: `
    {
        user(id: "${login}", idType: USERNAME) {
            username
        }
    }
  `,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUser().token}`,
      },
    }
  )
}

export const logout = callback => {
  setUser({})
  callback()
}
