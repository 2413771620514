import React, { Component } from "react"
import { graphql, navigate } from "gatsby"

import { isLoggedIn, getUser, logout } from "../../services/auth"
import { isBrowser } from "../../helpers"

import Layout from "../../components/layout"

class SalesOffers extends Component {
  constructor(props) {
    super(props)

    this.state = {
      authenticated: false,
      salesOffers: null,
      loading: true,
    }

    isBrowser && this.handleAuthentication()
  }

  async handleAuthentication() {
    const response = await isLoggedIn()

    if (response.data.errors) {
      navigate("/account/login")
    } else {
      this.setState({
        authenticated: true,
      })
    }
  }

  handleLogout() {
    logout(() => navigate("/account/login"))
  }

  render() {
    const { firstName, lastName, login } = getUser()
    // const { loading } = this.state

    return (
      <Layout
        meta={{
          ...this.props.data.wpPage.Meta,
          canonical: "/account/offers/",
        }}
        path={"a"}
        cta={"a"}
        themeOptions={this.props.data.wp.themeOptions}
      >
        {!login && <p>Redirecting...</p>}
        {firstName && (
          <p>
            Hello {firstName} {lastName}
          </p>
        )}
        <button onClick={() => this.handleLogout()}>Sign Out</button>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    wpPage(uri: { eq: "/" }) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default SalesOffers
